
import { Options, Vue, prop } from "vue-class-component";
import debounce from "lodash/debounce";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { TopUpVerificationController } from "@/app/ui/controllers/TopUpVerificationController";

class Prop {
  modelValue = prop<any>({
    type: Object,
    required: true
  });
  isDisabled = prop<boolean>({
    type: Boolean,
    default: false
  });
}

@Options({
  emits: ["reset", "update:modelValue", "filter", "opened"],
  components: {},
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class TopUpVerificationListFilter extends Vue.with(Prop) {
  mounted() {
    this.fetchBankList();
    this.fetchListVerifier();
  }

  isChevron = false;
  openFilter = false;

  resetFilter() {
    this.filterData = {
      partner: "",
      status: "",
      bank: "",
      verifiedBy: ""
    };
    this.onChange();
  }

  filterData = {
    partner: "",
    status: "",
    bank: "",
    verifiedBy: ""
  };

  get sumFilter() {
    return (
      (this.filterData.partner !== "" ? 1 : 0) +
      (this.filterData.status !== "" ? 1 : 0) +
      (this.filterData.bank !== "" ? 1 : 0) +
      (this.filterData.verifiedBy !== "" ? 1 : 0)
    );
  }

  onChange() {
    this.$emit("update:modelValue", this.filterData);
    this.$emit("filter");
  }

  // topup status
  topUpStatusOpened = false;
  selectedTopUpStatus = "";
  get topUpStatusOptions() {
    return [
      { name: "Pilih status", value: "" },
      { name: "Pending", value: "pending" },
      { name: "Verified", value: "verified" },
      { name: "Approved", value: "approved" },
      { name: "Verification Rejected", value: "verification_rejected" },
      { name: "Rejected", value: "rejected" }
    ];
  }
  onSelectTopUpStatus(name: string, value: string) {
    if (this.filterData.status === value) {
      this.filterData.status = "";
    } else {
      this.filterData.status = value;
    }
    this.topUpStatusOpened = false;
    this.onChange();
  }

  // partner list
  partnerList: Array<any> = [];
  loadingPartnerList = false;
  fetchPartnerList = debounce(async (search: string) => {
    this.loadingPartnerList = true;
    this.partnerList = await PartnerController.fetchListPartnerOptions({
      page: 1,
      limit: 10,
      search: search
    });
    this.loadingPartnerList = false;
  });
  onSelectPartner(items: any) {
    this.filterData.partner = items;
    this.onChange();
  }

  // bank
  bankOptions: string[] = [];
  async fetchBankList() {
    this.bankOptions = await TopUpVerificationController.getListBank();
  }
  get bankList() {
    const bankList: any[] = [
      {
        name: "Pilih bank penerima",
        value: ""
      }
    ];
    const bankListFromApi = this.bankOptions.map((item: any) => {
      return {
        name: item,
        value: item
      };
    });
    return [...bankList, ...bankListFromApi];
  }
  isOpenBank = false;
  onOpenSelectBank() {
    this.isOpenBank = true;
  }
  onCloseSelectBank() {
    this.isOpenBank = false;
  }
  onSelectBank(key: string, value: string) {
    this.onCloseSelectBank();
    this.filterData.bank = value;
    this.onChange();
  }

  // verifier
  verifierOptions: Array<any> = [];
  async fetchListVerifier() {
    this.verifierOptions = await TopUpVerificationController.getListVerifier();
  }
  get verifierList() {
    const verifierList: any[] = [
      {
        name: "Pilih nama",
        value: ""
      }
    ];
    const verifierListFromApi = this.verifierOptions.map((item: any) => {
      return {
        name: item.actorName,
        value: item.actorId
      };
    });
    return [...verifierList, ...verifierListFromApi];
  }
  isOpenVerifier = false;
  onOpenSelectVerifier() {
    this.isOpenVerifier = true;
  }
  onCloseSelectVerifier() {
    this.isOpenVerifier = false;
  }
  onSelectVerifier(key: string, value: string) {
    this.onCloseSelectVerifier();
    this.filterData.verifiedBy = value;
    this.onChange();
  }
}
