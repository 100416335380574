
import { Vue, Options, prop } from "vue-class-component";
import ModalForm from "@/app/ui/components/modal-form/index.vue";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { COD_TYPE_DATA, STATUS_LIST_DATA } from "@/domain/entities/HoldBalanceHistory";
import { ReportController } from "@/app/ui/controllers/ReportController";
import { ReportApiRequest } from "@/domain/entities/Report";
import { formatDateNumber } from "@/app/infrastructures/misc/Utils";

class Props {
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
}
@Options({
  emits: ["update:modelValue"],
  components: {
    ModalForm
  }
})
export default class ModalDownload extends Vue.with(Props) {
  onClose() {
    this.$emit("update:modelValue", false);
    this.onReset();
  }

  mounted() {
    this.onReset();
  }

  onReset() {
    this.params = new ApiRequestList({
      statusData: new OptionsClass({
        name: "Pilih Status"
      })
    });
  }

  apiEntities = new ResponsePayloadV2();
  params = new ApiRequestList();

  get statusData() {
    return STATUS_LIST_DATA.map((item: OptionsClass) => {
      if (item.name === "Semua Status") {
        return new OptionsClass({
          ...item,
          value: "all"
        });
      }
      return new OptionsClass(item);
    });
  }
  onSelectStatus(status: OptionsClass): void {
    this.params.statusData = status;
    this.params.status = status.value;
  }

  get codTypeData() {
    return COD_TYPE_DATA;
  }

  onSelectCodType(codType: OptionsClass) {
    this.params.codType = codType.value;
  }

  // time range picker
  minDate: any = null;
  maxDate: any = null;
  tempStartDate: any = null;
  tempEndDate: any = null;
  isStartDate = true;
  setDay(day: any) {
    if (this.isStartDate) {
      this.tempStartDate = this.params.startDate;
      this.tempEndDate = this.params.endDate;
      this.params.startDate = null;
      this.params.endDate = null;
      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - 89)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + 89)
      );
      this.isStartDate = false;
    } else {
      this.params.endDate && (this.isStartDate = true);
    }
  }
  resetDateRange(): void {
    this.minDate = null;
    this.maxDate = null;
  }
  cancelRangePick() {
    if (!this.isStartDate) {
      this.resetDateRange();
      this.isStartDate = true;
      this.params.startDate = this.tempStartDate;
      this.params.endDate = this.tempEndDate;
      this.tempStartDate = null;
      this.tempEndDate = null;
    }
  }
  setDateRange(value: Array<any>) {
    this.resetDateRange();
    this.params.startDate = value[0];
    this.params.endDate = value[1];
  }

  get isDisabled(): boolean {
    return (
      !this.params.status || !this.params.startDate || !this.params.endDate || !this.params.codType
    );
  }

  async onDownloadCsv() {
    await ReportController.getDownloadReport(
      new ReportApiRequest({
        service: "hydra",
        feature: "report",
        title: "hold_balance_history",
        status: this.params.status === "all" ? "" : this.params.status,
        startDate: formatDateNumber(this.params.startDate),
        endDate: formatDateNumber(this.params.endDate),
        extension: "xlsx",
        async: true,
        codType: this.params.codType
      })
    );
    this.onClose();
  }
}
