
import { Options, Vue } from "vue-class-component";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
import {
  COD_TYPE_DATA,
  HoldBalanceEntity,
  HoldBalanceHistoryDetail,
  STATUS_LIST_DATA
} from "@/domain/entities/HoldBalanceHistory";
import DownloadRequest from "@/app/ui/components/widget-download-request/index.vue";
import {
  formatDate,
  formatDateNumber,
  parsingErrorResponse,
  whatsapp
} from "@/app/infrastructures/misc/Utils";
import {
  ApiRequestList,
  OptionsClass,
  QueryParamsEntities2
} from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import { HoldBalanceHistoryController } from "@/app/ui/controllers/HoldBalanceHistoryController";
import ModalDownload from "./components/modal-download.vue";

@Options({
  components: {
    DownloadRequest,
    ModalDownload
  }
})
export default class List extends Vue {
  listEntities = new ResponsePayloadV2();
  get listData(): HoldBalanceEntity {
    return new HoldBalanceEntity(this.listEntities.data);
  }
  params = new ApiRequestList();

  onSearch(value: string) {
    this.params.search = value;
    this.listEntities.pagination.page = 1;
    this.fetchList();
  }

  // time range picker
  minDate: any = null;
  maxDate: any = null;
  tempStartDate: any = null;
  tempEndDate: any = null;
  isStartDate = true;
  setDay(day: any) {
    if (this.isStartDate) {
      this.tempStartDate = this.params.startDate;
      this.tempEndDate = this.params.endDate;
      this.params.startDate = null;
      this.params.endDate = null;
      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - 89)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + 89)
      );
      this.isStartDate = false;
    } else {
      this.params.endDate && (this.isStartDate = true);
    }
  }
  resetDateRange(): void {
    this.minDate = null;
    this.maxDate = null;
  }
  cancelRangePick() {
    if (!this.isStartDate) {
      this.resetDateRange();
      this.isStartDate = true;
      this.params.startDate = this.tempStartDate;
      this.params.endDate = this.tempEndDate;
      this.tempStartDate = null;
      this.tempEndDate = null;
    }
  }
  setDateRange(value: Array<any>) {
    this.resetDateRange();
    this.params.startDate = value[0];
    this.params.endDate = value[1];
    if (value[0] && value[1]) {
      this.listEntities.pagination.page = 1;
      this.fetchList();
    }
  }

  async fetchList() {
    this.listEntities.loading = true;
    try {
      const res = await HoldBalanceHistoryController.getList(
        new ApiRequestList({
          ...this.listEntities.pagination,
          sttNo: this.params.search,
          status: this.params.statusData.value,
          startDate: formatDateNumber(this.params.startDate || ""),
          endDate: formatDateNumber(this.params.endDate || ""),
          codType: this.params.codType ?? "all"
        })
      );
      this.listEntities = new ResponsePayloadV2({ ...res, message: "" });
    } catch (err) {
      this.listEntities.message = parsingErrorResponse(err).type.replace(
        /-error/g,
        ""
      );
    } finally {
      this.listEntities.loading = false;
    }
  }
  get isEmptyList(): boolean {
    return (
      !this.listEntities.data.length &&
      !this.params.search &&
      !this.params.status &&
      !this.listEntities.message
    );
  }

  statusData = STATUS_LIST_DATA;
  onSelectStatus(status: OptionsClass): void {
    this.params.statusData = status;
    this.listEntities.pagination.page = 1;
    this.fetchList();
  }

  isDownload = false;
  onDownload(value: boolean) {
    this.isDownload = value;
  }

  columns = [
    {
      name: "No. STT",
      styleHead: "text-black-lp-300 w-48 text-left whitespace-nowrap",
      render: (item: HoldBalanceHistoryDetail) => {
        return `<div class="text-black-lp-300 capitalize text-left">${item.hbhSttNo}</div>`;
      }
    },
    {
      name: "Tanggal Booking",
      styleHead: "text-black-lp-300 w-60 text-left whitespace-nowrap",
      render: (item: HoldBalanceHistoryDetail) => {
        return `<div class="text-black-lp-300 capitalize overflow-ellipsis text-left">${formatDate(
          item.hbhBookingAt
        )}</div>`;
      }
    },
    {
      name: "Booking Shipment",
      styleHead: "text-black-lp-300 w-48 text-left whitespace-nowrap",
      render: (item: HoldBalanceHistoryDetail) => {
        return `<div class="text-left">${formatPrice(
          item.hbhBookingShipmentFee
        )}</div>`;
      }
    },
    {
      name: "Booking Return",
      styleHead: "text-black-lp-300 w-48 text-left whitespace-nowrap",
      render: (item: HoldBalanceHistoryDetail) => {
        return `<div class="text-left">${formatPrice(
          item.hbhBookingReturnFee
        )}</div>`;
      }
    },
    {
      name: "Booking COD",
      styleHead: "text-black-lp-300 w-48 text-left whitespace-nowrap",
      render: (item: HoldBalanceHistoryDetail) => {
        return `<div class="text-left">${formatPrice(
          item.hbhBookingCodFee
        )}</div>`;
      }
    },
    {
      name: "Total Biaya STT",
      styleHead: "text-black-lp-300 w-48 text-left whitespace-nowrap",
      render: (item: HoldBalanceHistoryDetail) => {
        return `<div class="capitalize flex flex-col"><div class="text-left ${
          item.hbhStatus === "hold_balance"
            ? "text-red-lp-500"
            : "text-green-lp-100"
        }">${formatPrice(item.hbhTotalFee)}</div></div>`;
      }
    },
    {
      name: "Nama Pengirim",
      styleHead: "w-48 text-left whitespace-nowrap",
      key: "actionable_column",
      render: (item: HoldBalanceHistoryDetail) =>
        `<div class='text-black-lp-300'>${item.hbhSenderName}</div>` +
        `<div class='text-black-lp-300'>${item.hbhSenderPhone}</div>`,
      showButton: () => true,
      styleCustom: "items-end gap-x-2",
      icon: "whatsapp",
      disableButtonValue: false,
      onClickValue: (item: HoldBalanceHistoryDetail) =>
        whatsapp(item.hbhSenderPhone, "")
    },
    {
      name: "Status",
      styleHead: "text-black-lp-300 w-56 text-left whitespace-nowrap",
      render: (item: HoldBalanceHistoryDetail) => {
        const data = STATUS_LIST_DATA.find(
          status => status.value === item.hbhStatus
        );
        return ChipsStyles({ title: data?.name, status: data?.key || "" });
      }
    },
    {
      name: "Atur",
      key: "button_column",
      styleHead: "w-24 text-left",
      styleButton: (item: HoldBalanceHistoryDetail) => ({
        outline: true,
        icon: "option-gray",
        textColor: "gray-lp-400",
        color: "gray-lp-400",
        clickFunction: () => null,
        groupOptionsClass: "w-40 text-gray-lp-400",
        groupOptions: [
          {
            icon: "eye",
            iconV2: true,
            iconV2Fill: true,
            label: "Detail",
            textColor: "gray-lp-300",
            iconColor: "gray-lp-600",
            clickFunction: () => {
              window.open(`/shipment/booking/${item.hbhSttId}`, "_blank");
            }
          },
          {
            icon: "track",
            iconV2: true,
            iconV2Fill: true,
            label: "Tracking",
            textColor: "gray-lp-300",
            iconColor: "gray-lp-600",
            clickFunction: async () => {
              window.open(
                `/shipment/tracking/stt?${
                  new QueryParamsEntities2({
                    "stt-no": item.hbhSttNo
                  }).queryString
                }`,
                "_blank"
              );
            }
          }
        ]
      })
    }
  ];

  get totalHoldBalanceHistory() {
    return formatPrice(this.listData.totalHoldBalanceHistory);
  }

  get codTypeData() {
    return COD_TYPE_DATA;
  }

  onSelectCodType(codType: OptionsClass) {
    this.params.codType = codType.value;
    this.listEntities.pagination.page = 1;
    this.fetchList();
  }
}
