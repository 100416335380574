const levelingTooltip = (partnerPosTieringLevel: string) => {
    const levelData: any = {
        "bronze": {
            message: "Tingkatkan pendapatan Anda lebih dari Rp 10.000.000 untuk ke level selanjutnya"
        },
        "silver": {
            message: "Tingkatkan pendapatan Anda lebih dari Rp 25.000.000 untuk ke level selanjutnya"
        },
        "gold": {
            message: "Tingkatkan pendapatan Anda lebih dari Rp 50.000.000 untuk ke level selanjutnya"
        },
        "platinum": {
            message: "Tingkatkan pendapatan Anda lebih dari Rp 150.000.000 untuk ke level selanjutnya"
        },
        "diamond": {
            message: "Tingkatkan pendapatan Anda lebih dari Rp 150.000.000 untuk mempertahankan level"
        }
      }
  
      return levelData[partnerPosTieringLevel];
}

export default levelingTooltip;