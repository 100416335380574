
import { Vue, Options } from "vue-class-component";
import EmptyList from "@/app/ui/components/empty-list/index.vue";
import ModalCreateBeneficiaryAccount from "@/app/ui/views/beneficiary-account-management/component/modal-create-beneficiary-account.vue";
import { BeneficiaryAccountController } from "@/app/ui/controllers/BeneficiaryAccountController";

@Options({
  components: {
    EmptyList,
    ModalCreateBeneficiaryAccount
  }
})
export default class BeneficiaryAccountList extends Vue {
  get currentRoute() {
    return this.$route.meta.name;
  }

  buttonActionEmpty = {
    textColor: "white",
    title: "Tambah Rekening Bank",
    iconLeft: "plus-circle-white"
  };
  modalCreateBeneficiaryAccount = false;
  openModalCreateBeneficiaryAccount() {
    this.modalCreateBeneficiaryAccount = true;
  }
  deleteBeneficiaryAccount() {
    BeneficiaryAccountController.deleteBeneficiaryAccount();
  }

  get beneficiaryAccountList() {
    return BeneficiaryAccountController.beneficiaryAccountList;
  }

  columns = [
    {
      name: "Nama Bank",
      styleHead: "w-3/12 text-left",
      render: (item: any) => {
        return item.beneficiaryBankName;
      }
    },
    {
      name: "Nomor Rekening",
      styleHead: "w-3/12 text-left",
      render: (item: any) => {
        return item.beneficiaryAccountNumber;
      }
    },
    {
      name: "Nama Rekening",
      styleHead: "w-4/12 text-left",
      render: (item: any) => {
        return item.beneficiaryAccountName;
      }
    },
    {
      name: "Action",
      key: "button_column",
      styleHead: "w-1/12 text-right",
      styleButton: () => {
        return {
          flat: true,
          dense: true,
          class: "text-center",
          icon: "pencil-alt-grey",
          clickFunction: () => {
            // to do edit
          }
        };
      }
    },
    {
      name: "",
      key: "actionable_column",
      styleHead: "w-1/12 text-left",
      showButton: () => {
        return true;
      }
    }
  ];
}
