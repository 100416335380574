import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-end gap-x-6 py-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_button = _resolveComponent("lp-button")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_lp_button, {
      class: "w-3/12",
      customClass: "w-full py-1.5 text-red-lp-100",
      outline: "",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('open-verify', 'reject'))),
      title: "Reject"
    }),
    (_ctx.permissionCheck)
      ? (_openBlock(), _createBlock(_component_lp_button, {
          key: 0,
          class: "w-3/12",
          customClass: "w-full py-1.5",
          "text-color": "white",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('open-verify', 'verify'))),
          title: "Verifikasi"
        }))
      : _createCommentVNode("", true)
  ]))
}