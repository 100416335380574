import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skeleton = _resolveComponent("skeleton")!

  return (_openBlock(), _createBlock("div", null, [
    (_openBlock(), _createBlock(_Fragment, null, _renderList(2, (index) => {
      return _createVNode("div", {
        key: index,
        class: "grid grid-cols-2 gap-6 border-b py-6"
      }, [
        (_openBlock(), _createBlock(_Fragment, null, _renderList(8, (index) => {
          return _createVNode(_component_skeleton, {
            key: index,
            height: "1.5rem"
          })
        }), 64))
      ])
    }), 64))
  ]))
}