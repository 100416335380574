
import { defineAsyncComponent } from "vue";
import { Options, Vue } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import debounce from "lodash/debounce";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import dateToTime from "@/app/infrastructures/misc/common-library/DateToTime";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import { TransactionListData } from "@/domain/entities/Balance";
import { BalanceTransactionControllers } from "@/app/ui/controllers/BalanceTransactionControllers";
import {
  BalanceTransactionType,
  TransactionTypeGober
} from "@/domain/entities/BalanceTransaction";
import DownloadRequest from "@/app/ui/components/widget-download-request/index.vue";
import { BalanceMinusPenaltyController } from "@/app/ui/controllers/BalanceMinusPenaltyController";
const ModalWithdraw = defineAsyncComponent({
  loader: () => import("@/app/ui/views/balance/component/modal-withdraw.vue")
});
const ModalTopup = defineAsyncComponent({
  loader: () => import("@/app/ui/views/balance/component/modal-topup.vue")
});
const ModalFormTopupManual = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/balance/component/modal-form-topup-manual.vue")
});
const ModalDetailTopupManual = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/balance/component/modal-detail-topup-manual.vue")
});
const ModalListTopupHistory = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/balance/component/modal-list-topup-history.vue")
});
const ModalDownloadMutation = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/balance/component/modal-download-mutation.vue")
});
import { BalancePenaltyNotification } from "@/domain/entities/BalanceMinusPenalty";
import replaceDoubleBraces from "@/app/infrastructures/misc/common-library/ReplaceDoubleBraches";
import {
  ConvertObjectCamelToSnakeCase,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { FlagsPermissionSaldo } from "@/feature-flags/flags-permission-saldo";
import { FlagsPermissionTopUpManual } from '@/feature-flags/flags-permission-topup-manual';
import levelingPartner from "@/app/infrastructures/misc/common-library/LevelingPartner"
import levelTooltip from "@/app/infrastructures/misc/common-library/LevelTooltip";

@Options({
  components: {
    Tooltip,
    ModalTopup,
    ModalFormTopupManual,
    ModalListTopupHistory,
    ModalDetailTopupManual,
    ModalWithdraw,
    ModalDownloadMutation,
    DownloadRequest
  },
  computed: {
    isWithdrawDetail() {
      return (
        FlagsPermissionSaldo.permission_saldo_withdraw_view_detail.isEnabled() &&
        FlagsPermissionSaldo.permission_saldo_withdraw_request_detail.isEnabled()
      );
    },
    isEnableTopUp() {
      return FlagsPermissionTopUpManual.permission_topup_manual_enable.isEnabled();
    }
  }
})
export default class TransactionList extends Vue {
  async created() {
    BalanceController.initTransactionList();
    this.fullOptions = await BalanceTransactionControllers.getBalanceTransactionType(
      "v1"
    );
  }
  async mounted() {
    this.getBalancePenaltyNotification();
  }

  balanceNotification = new BalancePenaltyNotification();
  get isBalancePenaltyNotification(): boolean {
    const minus = BalanceController.balanceData.walletBalance < 0;
    const partnerIsCod =
      AccountController.accountData.account_type_detail?.partnerIsCodDelivery;
    return minus && partnerIsCod;
  }
  async getBalancePenaltyNotification() {
    if (this.isBalancePenaltyNotification) {
      const response = await BalanceMinusPenaltyController.getBalancePenaltyNotification();
      if (response.data) {
        const dateTime = (response.data as BalancePenaltyNotification)
          .npDueDate;
        const date = formatDate(dateTime, "DD MMMM YYYY");
        const time = formatDate(dateTime, "HH:mm");
        const styles: { [x: string]: string } = {
          info: "border-yellow-lp-300 bg-yellow-lp-400 text-chocolate-lp-300",
          alert: "border-yellow-lp-1100 bg-red-lp-50 text-red-lp-100"
        };
        this.balanceNotification = new BalancePenaltyNotification({
          ...response.data,
          npMessageSecond: replaceDoubleBraces(
            response.data.npMessageSecond,
            ConvertObjectCamelToSnakeCase({
              npBalance: this.balance,
              npDueDate: `${date}, Jam ${time}`
            })
          ),
          npStyles: styles[(response.data as BalancePenaltyNotification).npType]
        });
      }
    }
  }

  get balanceData() {
    return BalanceController.balanceData;
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get isOpenModalTopup() {
    return BalanceController.isOpenModalTopup;
  }

  openModalTopup() {
    return BalanceController.setOpenModalTopup(true);
  }

  closeModalTopup() {
    return BalanceController.setOpenModalTopup(false);
  }

  get balance() {
    return formatPrice(BalanceController.balanceData.walletBalance);
  }

  get showBalanceWarning() {
    return (
      BalanceController.balanceData.walletBalance <=
      this.dataProfile.limit_rule_min_balance +
        this.dataProfile.limit_rule_limit_border
    );
  }

  get balanceWarning() {
    return (
      BalanceController.balanceData.walletBalance <=
        this.dataProfile.limit_rule_min_balance +
          this.dataProfile.limit_rule_limit_border &&
      BalanceController.balanceData.walletBalance >=
        this.dataProfile.limit_rule_min_balance
    );
  }

  get balanceWarningMessage() {
    if (this.balanceWarning) {
      return "Saldo Anda mendekati limit";
    }
    return BalanceController.balanceData.walletBalance <
      this.dataProfile.limit_rule_min_balance
      ? "Saldo Anda dibawah limit"
      : "Saldo Anda mencapai limit";
  }

  get isDisableWithdraw() {
    return (
      BalanceController.balanceData.walletBalance <
        this.dataProfile.limit_rule_min_balance ||
      this.dataProfile.account_type === "client"
    );
  }

  //tooltip
  isOpenTooltip = false;
  openTooltip = debounce((val: boolean) => {
    this.isOpenTooltip = val;
  }, 50);

  // search
  get search() {
    return BalanceController.search;
  }
  onSearch(value: string) {
    BalanceController.setSearch(value);
    BalanceController.setFirstPage();
    this.fetchTransactionList();
  }

  clearSearch() {
    BalanceController.setSearch("");
    BalanceController.setFirstPage();
    this.fetchTransactionList();
  }

  // select
  fullOptions = new TransactionTypeGober();
  isOpenSelect = false;
  get options() {
    if (this.dataProfile.account_type === "client") {
      return [
        new BalanceTransactionType({
          name: "Semua",
          value: ""
        }),
        ...this.fullOptions.balanceClient
      ];
    }
    return [
      new BalanceTransactionType({
        name: "Semua",
        value: ""
      }),
      ...this.fullOptions.balancePos
    ];
  }
  get valueSelect() {
    return BalanceController.filter;
  }
  onSelect(value: any) {
    if (value.name === this.valueSelect.name) {
      BalanceController.setFilter({ name: "", value: "" });
    } else {
      BalanceController.setFilter(value);
    }
    BalanceController.setFirstPage();
    this.fetchTransactionList();
  }

  // time range picker
  minDate: any = null;
  maxDate: any = null;
  tempStartDate: any = null;
  tempEndDate: any = null;
  isStartDate = true;
  setDay(day: any) {
    if (this.isStartDate) {
      this.tempStartDate = this.startDate;
      this.tempEndDate = this.endDate;
      BalanceController.setStartDate(null);
      BalanceController.setEndDate(null);
      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - 89)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + 89)
      );
      this.isStartDate = false;
    } else {
      this.endDate && (this.isStartDate = true);
    }
  }

  cancelRangePick() {
    if (!this.isStartDate) {
      this.minDate = null;
      this.maxDate = null;
      this.isStartDate = true;
      BalanceController.setStartDate(this.tempStartDate);
      BalanceController.setEndDate(this.tempEndDate);
      this.tempStartDate = null;
      this.tempEndDate = null;
    }
  }

  get startDate() {
    return BalanceController.startDate;
  }

  get endDate() {
    return BalanceController.endDate;
  }

  setDateRange(value: Array<any>) {
    this.minDate = null;
    this.maxDate = null;
    BalanceController.setStartDate(value[0]);
    BalanceController.setEndDate(value[1]);
    value[0] && value[1] && BalanceController.setFirstPage();
    value[0] && value[1] && this.fetchTransactionList();
  }

  // table
  get transactionList() {
    return BalanceController.transactionList.data;
  }

  get pagination() {
    return BalanceController.transactionList.pagination;
  }

  fetchTransactionList() {
    BalanceController.fetchTransactionList("v1");
  }

  get isError() {
    return BalanceController.isError;
  }

  get errorCause() {
    return BalanceController.errorCause;
  }

  get isLoading() {
    return BalanceController.isLoading;
  }

  get isShowTransactionHistory() {
    return BalanceController.showTopupTransactionHistory;
  }

  get isShowTopupManualDetail() {
    return BalanceController.isShowRequestTopupManualDetail;
  }

  columns = [
    {
      name: "ID Transaksi Genesis",
      styleHead: "text-black-lp-300 w-56 text-left whitespace-no-wrap",
      render: (item: TransactionListData) => {
        return `<div class="text-black-lp-300 capitalize text-left break-all">${item.pgTrxNo}</div>`;
      }
    },
    {
      name: "No. STT",
      styleHead: "text-black-lp-300 w-40 text-left whitespace-no-wrap",
      render: (item: TransactionListData) => {
        return `<div class="text-black-lp-300 capitalize text-left">${
          item.pgTrxSttNo ? item.pgTrxSttNo : "-"
        }</div>`;
      }
    },
    {
      name: "Tipe",
      styleHead: "text-black-lp-300 w-40 text-left whitespace-no-wrap",
      render: (item: TransactionListData) => {
        return `<div class="text-black-lp-300 capitalize text-left break-words">${item.pgTrxType.replace(
          /_/g,
          " "
        )}</div>`;
      }
    },
    {
      name: "Aktivitas",
      styleHead: "text-black-lp-300 w-20 text-left whitespace-no-wrap",
      render: (item: TransactionListData) => {
        return `<div class="text-black-lp-300 capitalize overflow-ellipsis text-left">${item.pgTrxActivity}</div>`;
      }
    },
    {
      name: "Jumlah",
      styleHead: "text-black-lp-300 w-40 text-left whitespace-no-wrap",
      render: (item: TransactionListData) => {
        return `<div class=" capitalize flex flex-col"><div class="font-medium text-left ${
          item.pgTrxActivity === "credit"
            ? "text-green-lp-100"
            : "text-red-lp-500"
        }">${item.pgTrxActivity === "credit" ? "+" : "-"}${formatPrice(
          item.pgTrxAmount
        )}</div></div>`;
      }
    },
    {
      name: "Saldo Terakhir",
      styleHead: "text-black-lp-300 w-60 text-left whitespace-no-wrap",
      render: (item: TransactionListData) => {
        return `<div class="font-medium text-left">${formatPrice(
          item.pgTrxLastBalance
        )}</div>`;
      }
    },
    {
      name: "Tanggal Transaksi",
      styleHead: "text-black-lp-300 w-40 text-left whitespace-no-wrap",
      render: (item: TransactionListData) => {
        return `<div class="text-black-lp-300 capitalize overflow-ellipsis text-left">${this.formatDate(
          item.pgTrxCreatedAt
        )}</div>`;
      }
    },
    {
      name: "Catatan",
      styleHead: "text-black-lp-300 w-56 text-left whitespace-no-wrap",
      render: (item: TransactionListData) => {
        return `<div class="text-black-lp-300 capitalize text-left break-all">${
          item.pgTrxNote ? ellipsisString(item.pgTrxNote, 20) : "-"
        }</div>`;
      },
      vtooltip: (item: TransactionListData) =>
        item.pgTrxNote?.length > 20 ? item.pgTrxNote : ""
    },
    {
      name: "Nama Bank",
      styleHead: "text-black-lp-300 w-32 text-left whitespace-no-wrap",
      render: (item: TransactionListData) => {
        return `<div class="text-black-lp-300 capitalize text-left break-words">${
          item.pgTrxBeneficiaryBank ? item.pgTrxBeneficiaryBank : "-"
        }</div>`;
      }
    },
    {
      name: "Nama Rekening",
      styleHead: "text-black-lp-300 w-40 text-left whitespace-no-wrap",
      render: (item: TransactionListData) => {
        return `<div class="text-black-lp-300 capitalize break-words text-left">${
          item.pgTrxBeneficiaryName ? item.pgTrxBeneficiaryName : "-"
        }</div>`;
      }
    },
    {
      name: "No. Rekening",
      styleHead: "text-black-lp-300 w-40 text-left whitespace-no-wrap",
      render: (item: TransactionListData) => {
        return `<div class="text-black-lp-300 capitalize overflow-ellipsis text-left">${
          item.pgTrxBeneficiaryAccount ? item.pgTrxBeneficiaryAccount : "-"
        }</div>`;
      }
    },
    {
      name: "Genesis Referensi ID",
      styleHead: "text-black-lp-300 w-52 text-left whitespace-no-wrap",
      render: (item: TransactionListData) => {
        return `<div class="text-black-lp-300 capitalize break-all text-left">${
          item.pgTrxRefNo ? item.pgTrxRefNo : "-"
        }</div>`;
      }
    },
    {
      name: "Status",
      styleHead: "text-black-lp-300 w-24 text-left whitespace-no-wrap",
      render: (item: TransactionListData) => {
        const trxStatusHandled = this.handleTrxStatus(item.pgTrxStatus);
        return `<div class="capitalize overflow-ellipsis text-left flex flex-row gap-x-1 font-medium
         ${this.handleClassTextColorTrxStatus(trxStatusHandled)}">
            ${this.handleImgTrx(trxStatusHandled)}
            ${trxStatusHandled}
          </div>`;
      }
    }
  ];
  handleImgTrx(status: string) {
    if (status === "success") {
      return `<img class="w-3 max-w-none" src="${require("@/app/ui/assets/svg/status-success.svg")}" />`;
    }
    if (status === "failed") {
      return `<img class="w-3 max-w-none" src="${require("@/app/ui/assets/svg/status-failed.svg")}" />`;
    }
    return `<img class="w-3 max-w-none" src="${require("@/app/ui/assets/svg/status-request.svg")}" />`;
  }
  handleClassTextColorTrxStatus(status: string) {
    if (status === "success") {
      return "text-green-lp-100";
    }
    return status === "failed" ? "text-red-lp-750" : "text-yellow-lp-100";
  }
  handleTrxStatus(status: string) {
    status = status.toLowerCase();
    if (status === "approved") {
      return "success";
    }
    if (status === "rejected") {
      return "failed";
    }
    return status;
  }

  formatDate(date: string) {
    return `${dateToDateString(date)}, ${dateToTime(date)}`;
  }

  // modal withdraw
  isOpenModalWithdraw = false;
  openModalWithdraw() {
    if (this.isDisableWithdraw) {
      return;
    }
    this.isOpenModalWithdraw = true;
  }
  closeModalWithdraw() {
    this.isOpenModalWithdraw = false;
  }

  onModalDownload() {
    this.setModalDownloadMutation(true);
  }

  setModalDownloadMutation(boolean: boolean) {
    BalanceController.setModalDownloadMutation(boolean);
  }

  setModalSuccess(boolean: boolean) {
    BalanceController.setModalSuccess(boolean);
  }

  get isModalSuccessDownloadMutation() {
    return BalanceController.modalSuccess;
  }

  get isModalDownloadMutation() {
    return BalanceController.modalDownloadMutation;
  }

  get showTopupUploadForm() {
    return BalanceController.showTopupUploadForm;
  }

  get isReportDownload() {
    return true;
  }

  get accountIsForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  get balanceDeposit() {
    return formatPrice(AccountController.accountData.account_type_detail.partnerPosSaldoDeposit)
  }

  get level() {
    return levelingPartner(AccountController.accountData.account_type_detail.partnerPosTieringLevel);
  }

  get levelTooltip() {
    return levelTooltip(AccountController.accountData.account_type_detail.partnerPosTieringLevel)?.message;
  }

  isopenTooltipBalanceDeposit = false;
  openTooltipBalanceDeposit = debounce((val: boolean) => {
    this.isopenTooltipBalanceDeposit = val;
  }, 50);

  isopenTooltipBalanceAvailable = false;
  openTooltipBalanceAvailable = debounce((val: boolean) => {
    this.isopenTooltipBalanceAvailable = val;
  }, 50);

  isopenTooltipLevel = false;
  openTooltipLevel = debounce((val: boolean) => {
    this.isopenTooltipLevel = val;
  }, 50);
}
