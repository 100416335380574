import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_input = _resolveComponent("lp-input")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.isShow)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          title: _ctx.confirmValue.title,
          message: _ctx.confirmValue.message,
          image: "warning",
          isDisabled: _ctx.isDisabled,
          textSuccess: _ctx.confirmValue.textSuccess,
          textCancel: _ctx.confirmValue.textCancel,
          class: "px-8",
          onClose: _ctx.confirmationCancel,
          onSubmit: _ctx.confirmationSubmit
        }, {
          custom: _withCtx(() => [
            _createVNode("div", _hoisted_1, [
              _createVNode(_component_lp_input, {
                modelValue: _ctx.rejectedReason,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.rejectedReason = $event)),
                type: "textarea",
                placeholder: "Masukan alasan penolakan"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _: 1
        }, 8, ["title", "message", "isDisabled", "textSuccess", "textCancel", "onClose", "onSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.confirmationFinish)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 1,
          onSubmit: _ctx.closeModalSuccess,
          title: _ctx.titleFinish,
          message: _ctx.messageFinish,
          image: _ctx.imageFinish,
          textSuccess: _ctx.successFinish,
          class: "px-8"
        }, null, 8, ["onSubmit", "title", "message", "image", "textSuccess"]))
      : _createCommentVNode("", true),
    (_ctx.saveConfirmation)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 2,
          onSubmit: _ctx.saveRejectedTopUp,
          onClose: _ctx.closeSaveConfirmation,
          title: "Konfirmasi Penolakan",
          message: 
      `Anda yakin akan menolak transaksi top up ${
        _ctx.detail.actorName
      } dengan jumlah ${_ctx.formatPrice(_ctx.detail.amount)} ?`
    ,
          image: "are-you-sure",
          textSuccess: "Reject",
          textCancel: "Kembali",
          class: "px-8"
        }, null, 8, ["onSubmit", "onClose", "message"]))
      : _createCommentVNode("", true),
    (_ctx.isError)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 3,
          modal: "",
          "error-type": _ctx.errorCause,
          "on-try-again": _ctx.saveRejectedTopUp,
          "on-cancel": _ctx.handleError
        }, null, 8, ["error-type", "on-try-again", "on-cancel"]))
      : _createCommentVNode("", true)
  ], 64))
}