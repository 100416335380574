
import { Options, Vue } from "vue-class-component";
import { formatPrice } from "@/app/infrastructures/misc/Utils";
import { TopUpVerificationController } from "@/app/ui/controllers/TopUpVerificationController";

@Options({
  emits: ["update:isShow", "success", "cancel"],
  components: {},
  props: {
    isShow: {
      default: false,
      type: Boolean
    },
    id: {
      default: ""
    }
  },

  computed: {
    isDisabled() {
      return this.rejectedReason === "";
    }
  }
})
export default class TopUpRejectPopUp extends Vue {
  props: any = this.$props;
  confirmValue = {
    title: "Konfirmasi Penolakan",
    message: "Masukan alasan penolakan transaksi top up.",
    textSuccess: "Reject",
    textCancel: "Kembali",
    custom: true
  };

  // handling
  confirmationCancel() {
    this.$emit("cancel");
  }

  confirmationSubmit() {
    this.$emit("update:isShow", false);
    this.saveConfirmation = true;
  }

  isError = false;
  errorCause = "";

  handleError() {
    this.confirmationFinish = false;
    this.isError = false;
    this.errorCause = "";
  }

  // reason
  rejectedReason = "";

  // confirmation finish
  confirmationFinish = false;
  titleFinish = "";
  messageFinish = "";
  successFinish = "";
  imageFinish = "";
  finishConfirmationValue = {
    title: "Penolakan Berhasil !",
    textSuccess: "OK"
  };
  closeModalSuccess() {
    this.confirmationFinish = false;
    this.$emit("success");
  }

  get detail() {
    return TopUpVerificationController.topUpVerificationDetail;
  }
  formatPrice(value: any) {
    return formatPrice(value);
  }

  saveConfirmation = false;
  closeSaveConfirmation() {
    this.$emit("update:isShow", true);
    this.saveConfirmation = false;
  }
  async saveRejectedTopUp() {
    this.saveConfirmation = false;
    this.handleError();
    const resp = await TopUpVerificationController.rejectTopUpVerification({
      id: this.props.id,
      reasonRejected: this.rejectedReason
    });
    if (resp.success) {
      this.confirmationFinish = true;
      this.titleFinish = this.finishConfirmationValue.title;
      this.messageFinish = "Permintaan top up berhasil ditolak.";
      this.successFinish = this.finishConfirmationValue.textSuccess;
      this.imageFinish = "image-modal-success";
    } else {
      if (resp.message === "server") {
        this.confirmationFinish = true;
        this.titleFinish = "Penolakan Gagal !";
        this.messageFinish =
          "Terjadi kesalahan pada system. Silahkan coba lagi nanti. ";
        this.successFinish = "OK";
        this.imageFinish = "image-modal-failed";
      } else {
        this.isError = true;
        this.errorCause = resp.message;
      }
    }
    this.rejectedReason = "";
  }
}
