/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import { VuexModule, Module, Action, getModule } from "vuex-module-decorators";
import store from "@/store";
import { HoldBalanceHistoryPresenter } from "../presenters/HoldBalanceHistoryPresenter";
import { ApiRequestList } from "@/domain/entities/MainApp";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "hold-balance-history"
})
class HoldBalanceHistoryStore extends VuexModule {
  @Action
  public getList(params: ApiRequestList) {
    const presenter = container.resolve(HoldBalanceHistoryPresenter);
    return presenter.getList(params);
  }
}

export const HoldBalanceHistoryController = getModule(HoldBalanceHistoryStore);
