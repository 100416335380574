
import { Vue, Options, prop } from "vue-class-component";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { BeneficiaryAccountController } from "@/app/ui/controllers/BeneficiaryAccountController";

class Props {
  modelValue = prop<boolean>({
    default: false
  });
}
@Options({
  emits: ["update:modelValue"],
  components: {}
})
export default class ModalCreateBeneficiaryAccount extends Vue.with(Props) {
  mounted() {
    PartnerController.getListPartnerBank();
  }

  form = {
    beneficiaryBankName: "",
    beneficiaryAccountName: "",
    beneficiaryAccountNumber: ""
  };

  toggleDialog(value: boolean) {
    this.$emit("update:modelValue", value);
  }

  // bank
  get bankList() {
    const bankList: any[] = [
      {
        name: "Pilih Bank",
        value: ""
      }
    ];
    const bankListFromApi = PartnerController.partnerBank.map(bank => {
      return { name: bank.bankName, value: bank.bankCode };
    });
    return [...bankList, ...bankListFromApi];
  }
  isOpenBank = false;
  onOpenSelectBank() {
    this.isOpenBank = true;
  }
  onCloseSelectBank() {
    this.isOpenBank = false;
  }
  onSelectBank(key: string, value: string) {
    this.onCloseSelectBank();
    this.form.beneficiaryBankName = value;
  }

  formatRekening(value: string) {
    return value.replace(/[^0-9]+/g, "");
  }

  get isFormValid() {
    return !!(
      this.form.beneficiaryAccountNumber &&
      this.form.beneficiaryBankName &&
      this.form.beneficiaryAccountName
    );
  }

  saveBeneficiaryAccount() {
    if (!this.isFormValid) return;
    BeneficiaryAccountController.createBeneficiaryAccount(this.form);
    this.toggleDialog(false);
  }
}
