
import { Vue, Options } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import { TopUpVerificationController } from "@/app/ui/controllers/TopUpVerificationController";
import {
  formatInputMoney,
  formatPrice,
  parseInputFloatToFloat
} from "@/app/infrastructures/misc/Utils";

@Options({
  emits: ["cancel", "update:isShow", "success"],
  components: {
    Skeleton
  },
  props: {
    id: {
      default: ""
    },
    isShow: {
      default: false,
      type: Boolean
    }
  }
})
export default class TopUpVerifyPopUp extends Vue {
  props: any = this.$props;
  get detail() {
    return TopUpVerificationController.topUpVerificationDetail;
  }

  amountToBeVerified = "0";
  formatNumber(value: string) {
    return formatInputMoney(value.replace(/[\D]+/g, ""));
  }
  formatPrice(value: any) {
    return formatPrice(value);
  }

  onTopUpVerificationConfirmation() {
    if (this.isDisableButtonWithdraw) {
      return;
    }
    this.$emit("update:isShow", false);
    this.saveConfirmation = true;
  }

  isError = false;
  errorCause = "";
  closeModalError() {
    this.isError = false;
    this.errorCause = "";
  }

  // confirmation finish
  confirmationFinish = false;
  titleFinish = "";
  messageFinish = "";
  successFinish = "";
  imageFinish = "";
  closeModalSuccess() {
    this.confirmationFinish = false;
    this.$emit("success");
  }

  saveConfirmation = false;
  closeSaveConfirmation() {
    this.$emit("update:isShow", true);
    this.saveConfirmation = false;
  }
  async saveVerifyTopUp() {
    this.saveConfirmation = false;
    this.confirmationFinish = false;
    const resp = await TopUpVerificationController.verifyTopUpVerification({
      id: this.props.id,
      amount: Number(this.amountToBeVerified.replace(/\./g, ""))
    });
    if (resp.success) {
      this.confirmationFinish = true;
      this.titleFinish = "Verifikasi Berhasil !";
      this.messageFinish = "Permintaan top up berhasil diverifikasi.";
      this.successFinish = "OK";
      this.imageFinish = "image-modal-success";
    } else {
      if (resp.message === "server") {
        this.confirmationFinish = true;
        this.titleFinish = "Verifikasi Gagal !";
        this.messageFinish =
          "Terjadi kesalahan pada system. Silahkan coba lagi nanti.";
        this.successFinish = "OK";
        this.imageFinish = "image-modal-failed";
      } else {
        this.isError = true;
        this.errorCause = resp.message;
      }
    }
    this.amountToBeVerified = "0";
  }

  // validate amount to be verified
  get errorAmountToBeVerified() {
    return (
      parseInputFloatToFloat(this.amountToBeVerified) >
      Number(this.detail.amount)
    );
  }
  get isDisableButtonWithdraw() {
    return (
      this.errorAmountToBeVerified ||
      this.amountToBeVerified === "" ||
      parseInputFloatToFloat(this.amountToBeVerified) <= 0
    );
  }
}
