import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative font-montserrat" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalCreateBeneficiaryAccount = _resolveComponent("ModalCreateBeneficiaryAccount")!
  const _component_Header = _resolveComponent("Header")!
  const _component_EmptyList = _resolveComponent("EmptyList")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.modalCreateBeneficiaryAccount)
      ? (_openBlock(), _createBlock(_component_ModalCreateBeneficiaryAccount, {
          key: 0,
          modelValue: _ctx.modalCreateBeneficiaryAccount,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.modalCreateBeneficiaryAccount = $event))
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Header, { currentRoutes: _ctx.currentRoute }, null, 8, ["currentRoutes"]),
    (_ctx.beneficiaryAccountList.length === 0)
      ? (_openBlock(), _createBlock(_component_EmptyList, {
          key: 1,
          "button-action": _ctx.buttonActionEmpty,
          onAction: _ctx.openModalCreateBeneficiaryAccount,
          "custom-message": "Oops.. Belum ada data yang dapat ditampilkan"
        }, null, 8, ["button-action", "onAction"]))
      : (_openBlock(), _createBlock(_component_TableV2, {
          key: 2,
          columns: _ctx.columns,
          data: _ctx.beneficiaryAccountList,
          loading: false,
          "border-bottom": false,
          "hide-pagination": true,
          onRequest: ($event: any) => ({}),
          errorCause: '',
          onTryAgain: {},
          "delete-action": true,
          onDeleteItem: _ctx.deleteBeneficiaryAccount
        }, null, 8, ["columns", "data", "onDeleteItem"]))
  ]))
}