
import { Vue, Options } from "vue-class-component";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ROLE_ADMIN_FINANCE } from "@/app/infrastructures/misc/RolePermission";

@Options({
  emits: ["open-verify"],
  components: {}
})
export default class TopUpVerificationDetailFooter extends Vue {
  get permissionCheck(): boolean {
    const role = AccountController.accountData.role_type;
    return (
      role === ROLE_ADMIN_FINANCE.ADMIN_FINANCE ||
      role === ROLE_ADMIN_FINANCE.ADMIN_FINANCE_VERIFY
    );
  }
}
