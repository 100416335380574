
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options } from "vue-class-component";
import {
  ellipsisString,
  formatDateWithoutTime,
  formatPriceRP
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import {
  ROLE_ADMIN_FINANCE
} from "@/app/infrastructures/misc/RolePermission";
import TopUpVerificationListFilter from "@/app/ui/views/finance/topup-verification/components/topup-verification-list-filter.vue";
import debounce from "lodash/debounce";
import { TopUpVerificationListData } from "@/domain/entities/TopupVerification";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import TopUpVerificationDetailPage from "@/app/ui/views/finance/topup-verification/topup-verification-detail/index.vue";
import TopUpVerificationDetailFooter from "@/app/ui/views/finance/topup-verification/topup-verification-detail/topup-verification-detail-footer.vue";
import TopUpVerificationVerify from "@/app/ui/views/finance/topup-verification/topup-verification-verify/index.vue";
import { TopUpVerificationController } from "@/app/ui/controllers/TopUpVerificationController";
import { FlagsPermissionTopUpVerification } from "@/feature-flags/flags-permission-topup-verification";

@Options({
  components: {
    TopUpVerificationListFilter,
    EmptyState,
    TopUpVerificationDetailPage,
    TopUpVerificationDetailFooter,
    TopUpVerificationVerify
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class TopUpVerification extends Vue {
  mounted() {
    TopUpVerificationController.initDataTable();
  }
  dialogTopUpVerificationDetail = false;
  dialogTopUpVerify = false;
  topUpVerifyType = "verify";
  idDetailSelected = 0;

  onOpenTopUpVerify(type: string) {
    this.topUpVerifyType = type;
    this.dialogTopUpVerificationDetail = false;
    this.dialogTopUpVerify = true;
  }
  handleCancelTopUpVerify() {
    this.topUpVerifyType = "";
    this.dialogTopUpVerify = false;
    this.dialogTopUpVerificationDetail = true;
  }
  get detail() {
    return TopUpVerificationController.topUpVerificationDetail;
  }
  get isAbleToRejectOrVerify() {
    return (
      this.detail.status === "pending" &&
      FlagsPermissionTopUpVerification.permission_topup_verification_verify.isEnabled() &&
      (this.roleType === ROLE_ADMIN_FINANCE.ADMIN_FINANCE ||
        this.roleType === ROLE_ADMIN_FINANCE.ADMIN_FINANCE_VERIFY)
    );
  }
  get ableToViewDetail() {
    return FlagsPermissionTopUpVerification.permission_topup_verification_detail_view.isEnabled();
  }
  get roleType() {
    return AccountController.accountData.role_type;
  }

  // Table
  get columns() {
    return [
      {
        name: "ID",
        styleHead:
          "w-36 text-left whitespace-no-wrap align-top text-black-lp-300 text-12px xxl:text-14px font-semibold",
        render: (item: TopUpVerificationListData) => {
          return `<p class="w-full break-all text-left">${ellipsisString(
            item.id,
            40
          )}</p>`;
        }
      },
      {
        name: "ID Transaksi Genesis",
        styleHead:
          "w-64 text-left whitespace-no-wrap align-top text-black-lp-300 text-12px xxl:text-14px font-semibold",
        render: (item: TopUpVerificationListData) => {
          return `<p class="w-full break-all text-left">${ellipsisString(
            item.genesisTransactionId,
            40
          )}</p>`;
        }
      },
      {
        name: "Nama Partner",
        styleHead:
          "w-56 text-left whitespace-no-wrap align-top text-black-lp-300 text-12px xxl:text-14px font-semibold",
        render: (item: TopUpVerificationListData) => {
          return `<p class="w-full break-all text-left">${ellipsisString(
            item.partnerName,
            40
          )}</p>`;
        }
      },
      {
        name: "Status",
        styleHead:
          "w-64 text-left whitespace-no-wrap align-top text-black-lp-300 text-12px xxl:text-14px font-semibold",
        render: (item: TopUpVerificationListData) => {
          return `
          <div class='capitalize ${this.onChipStatus(item.status)}'>
            <p class="w-full break-all text-left">${ellipsisString(
              item.status,
              40
            )}</p>
          </div>`;
        }
      },
      {
        name: "Jumlah Permintaan",
        styleHead:
          "w-56 text-left whitespace-no-wrap align-top text-black-lp-300 text-12px xxl:text-14px font-semibold",
        render: (item: TopUpVerificationListData) => {
          return `<p class="w-full break-all text-left text-green-lp-100">${formatPriceRP(
            item.requestAmount
          )}</p>`;
        }
      },
      {
        name: "Jumlah Terverifikasi",
        styleHead:
          "w-56 text-left whitespace-no-wrap align-top text-black-lp-300 text-12px xxl:text-14px font-semibold",
        render: (item: TopUpVerificationListData) => {
          return `<p class="w-full break-all text-left text-green-lp-100">${formatPriceRP(
            item.verificationAmount
          )}</p>`;
        }
      },
      {
        name: "Nama Bank",
        styleHead:
          "w-56 text-left whitespace-no-wrap align-top text-black-lp-300 text-12px xxl:text-14px font-semibold",
        render: (item: TopUpVerificationListData) => {
          return `<p class="w-full break-all text-left">${ellipsisString(
            item.bankNameSender,
            40
          )}</p>`;
        }
      },
      {
        name: "Nama Rekening",
        styleHead:
          "w-56 text-left whitespace-no-wrap align-top text-black-lp-300 text-12px xxl:text-14px font-semibold",
        render: (item: TopUpVerificationListData) => {
          return `<p class="w-full break-all text-left">${ellipsisString(
            item.accountNameSender,
            40
          )}</p>`;
        }
      },
      {
        name: "No. Rekening",
        styleHead:
          "w-56 text-left whitespace-no-wrap align-top text-black-lp-300 text-12px xxl:text-14px font-semibold",
        render: (item: TopUpVerificationListData) => {
          return `<p class="w-full break-all text-left">${ellipsisString(
            item.accountNumberSender,
            40
          )}</p>`;
        }
      },
      {
        name: "Tanggal Transaksi",
        styleHead: "w-48 text-left whitespace-no-wrap align-top",
        render: (item: TopUpVerificationListData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.transactionDate
          )}<div class="truncate text-gray-lp-600 w-32">${
            item.transactionBy
          }</div></div>`;
        }
      },
      {
        name: "Tanggal Diverifikasi",
        styleHead: "w-48 text-left whitespace-no-wrap align-top",
        render: (item: TopUpVerificationListData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.verificationDate
          )}<div class="truncate text-gray-lp-600 w-32">${
            item.verificationBy
          }</div></div>`;
        }
      },
      {
        name: "Alasan Penolakan",
        styleHead:
          "w-36 text-left whitespace-no-wrap align-top text-black-lp-300 text-12px xxl:text-14px font-semibold",
        render: (item: TopUpVerificationListData) => {
          return `<p class="w-full break-all text-left">${ellipsisString(
            item.note,
            40
          )}</p>`;
        }
      }
    ];
  }
  onChipStatus(status: string) {
    let chip = "";
    switch (status) {
      case "pending":
        chip =
          "bg-gray-lp-200 text-black-lp-300 rounded-full px-2 text-12px xxl:text-14px";
        break;
      case "verified":
        chip =
          "bg-yellow-lp-200 text-yellow-lp-100 rounded-full px-2 text-12px xxl:text-14px";
        break;
      case "verification rejected":
      case "rejected":
        chip =
          "bg-red-lp-600 text-red-lp-500 rounded-full px-2 text-12px xxl:text-14px";
        break;
      case "approved":
        chip =
          "bg-green-lp-300 text-green-lp-200 rounded-full px-2 text-12px xxl:text-14px";
        break;
      default:
        break;
    }
    return chip;
  }
  get pagination() {
    return TopUpVerificationController.topUpVerificationList.pagination;
  }
  fetchTopUpVerificationList() {
    TopUpVerificationController.getTopUpVerificationList();
  }
  get topUpVerificationList() {
    return TopUpVerificationController.topUpVerificationList.data;
  }
  get isLoading() {
    return TopUpVerificationController.isLoading;
  }
  get errorCause() {
    return TopUpVerificationController.errorCause;
  }
  get isError() {
    return TopUpVerificationController.isError;
  }
  get isFirstRequest() {
    return TopUpVerificationController.isFirstRequest;
  }

  // advance filter
  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }
  isAdvancedFilterOpened = false;
  onChangeAdvancedFilter = debounce(event => {
    TopUpVerificationController.setAdvancedFilter(event);
    this.fetchTopUpVerificationList();
  }, 500);
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onResetAdvancedFilter = debounce(() => {
    TopUpVerificationController.setAdvancedFilter({
      status: "",
      partner: "",
      verifiedBy: "",
      bank: ""
    });
    this.fetchTopUpVerificationList();
  }, 500);
  get advancedFilterData() {
    return TopUpVerificationController.advancedFilter;
  }

  // detail
  onClickRow(data: any) {
    this.idDetailSelected = data.id;
    this.dialogTopUpVerificationDetail = true;
  }
}
