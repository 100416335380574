
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import TopUpVerificationDetailLazyLoad from "@/app/ui/views/finance/topup-verification/topup-verification-detail/topup-verification-detail-lazy-load.vue";
import { TopUpVerificationController } from "@/app/ui/controllers/TopUpVerificationController";
import {
  formatPrice,
  formatDateWithoutTime,
  fileNameFromUrl,
  fileExtensionFromUrl,
  downloadFile
} from "@/app/infrastructures/misc/Utils";

@Options({
  components: {
    TopUpVerificationDetailLazyLoad,
    Title
  },
  props: {
    id: {
      default: ""
    }
  }
})
export default class TopUpVerificationDetailPage extends Vue {
  props: any = this.$props;
  mounted() {
    TopUpVerificationController.fetchTopUpVerificationDetail({
      id: this.props.id
    });
  }
  get loading() {
    return TopUpVerificationController.isLoading;
  }
  get error() {
    return TopUpVerificationController.isError;
  }
  get errorCause() {
    return TopUpVerificationController.errorCause;
  }
  get detail() {
    return TopUpVerificationController.topUpVerificationDetail;
  }
  get chipsStatusStyle() {
    const sameValue =  {
      textColor: "red-lp-500",
      color: "red-lp-600"
    };

    switch (this.detail.status) {
      case "approved":
        return {
          textColor: "green-lp-200",
          color: "green-lp-300"
        };
      case "rejected":
        return sameValue;
      case "verified":
        return {
          textColor: "yellow-lp-100",
          color: "yellow-lp-200"
        };
      case "verification_rejected":
        return sameValue;
      default:
        return {
          textColor: "black-lp-400",
          color: "gray-lp-200"
        };
    }
  }
  formatPrice(val: any) {
    return formatPrice(val);
  }
  formatDate(val: any) {
    if (val) {
      return formatDateWithoutTime(val);
    }
    return "";
  }

  downloadFile(url: string) {
    downloadFile(url, fileNameFromUrl(url), fileExtensionFromUrl(url));
  }
}
