import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  style: {"width":"34rem"},
  class: "space-y-8"
}
const _hoisted_2 = { class: "py-1.5" }
const _hoisted_3 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_LpInput = _resolveComponent("LpInput")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    title: "Tambah Rekening Bank",
    "model-value": _ctx.modelValue,
    "onUpdate:modelValue": _ctx.toggleDialog,
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_DataWrapper, { label: "Nama Bank" }, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_2, [
              _createVNode(_component_Select, {
                onOpenSelect: _ctx.onOpenSelectBank,
                onCloseSelect: _ctx.onCloseSelectBank,
                isOpenMenu: _ctx.isOpenBank,
                value: _ctx.form.beneficiaryBankName,
                onChooseSelect: _ctx.onSelectBank,
                dataSelect: _ctx.bankList,
                class: "w-full"
              }, null, 8, ["onOpenSelect", "onCloseSelect", "isOpenMenu", "value", "onChooseSelect", "dataSelect"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_DataWrapper, { label: "Nama Rekening" }, {
          default: _withCtx(() => [
            _createVNode(_component_LpInput, {
              modelValue: _ctx.form.beneficiaryAccountName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.beneficiaryAccountName = $event)),
              placeholder: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_DataWrapper, { label: "Nomor Rekening" }, {
          default: _withCtx(() => [
            _createVNode(_component_LpInput, {
              modelValue: _ctx.form.beneficiaryAccountNumber,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.beneficiaryAccountNumber = $event)),
              placeholder: "",
              format: _ctx.formatRekening
            }, null, 8, ["modelValue", "format"])
          ]),
          _: 1
        }),
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_LpButton, {
            disabled: !_ctx.isFormValid,
            title: "Simpan",
            onClick: _ctx.saveBeneficiaryAccount,
            "text-color": "white"
          }, null, 8, ["disabled", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["model-value", "onUpdate:modelValue"]))
}