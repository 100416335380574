import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_up_verify_pop_up = _resolveComponent("top-up-verify-pop-up")!
  const _component_top_up_reject_pop_up = _resolveComponent("top-up-reject-pop-up")!

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.formType === 'verify')
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode(_component_top_up_verify_pop_up, {
            "is-show": _ctx.modelValue,
            "onUpdate:isShow": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
            id: _ctx.id,
            onSuccess: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('refresh'))),
            onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('cancel')))
          }, null, 8, ["is-show", "id"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.formType === 'reject')
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode(_component_top_up_reject_pop_up, {
            id: _ctx.id,
            "is-show": _ctx.modelValue,
            onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('cancel'))),
            onSuccess: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('refresh'))),
            "onUpdate:isShow": _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
          }, null, 8, ["id", "is-show"])
        ]))
      : _createCommentVNode("", true)
  ]))
}