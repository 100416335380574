
import { Vue, Options } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";

@Options({
  components: {
    Skeleton
  }
})
export default class TopUpVerificationDetailLazyLoad extends Vue {}
