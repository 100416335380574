
import { Vue, Options } from "vue-class-component";
import TopUpRejectPopUp from "@/app/ui/views/finance/topup-verification/topup-verification-verify/topup-reject-pop-up.vue";
import TopUpVerifyPopUp from "@/app/ui/views/finance/topup-verification/topup-verification-verify/topup-verify-pop-up.vue";

@Options({
  emits: ["cancel", "update:modelValue"],
  components: {
    TopUpRejectPopUp,
    TopUpVerifyPopUp
  },
  props: {
    formType: {
      default: "verify",
      type: String
    },
    modelValue: {
      required: true
    },
    id: {
      default: ""
    }
  }
})
export default class TopUpVerificationVerify extends Vue {}
